// General
button:focus {
  outline: none
}
.timer-icon {
  height: 24px;
}
.long-text {
  white-space: break-spaces;
}
// .leaflet-control-attribution a {display: none;}

// Top
body{
  overflow: hidden;
}

.logo {
  max-height: 30px;
}

// Filters
.filter {
  .filter-buttons {
    display: flex;
    place-self: end;
  }
  &.mobile {
    display: none;
  }
}


// Listing
.offers-list {
  background-color: #fbf9f9;
  overflow: hidden;
}

.list {
  overflow: auto;
  height: 100%;
  padding-bottom: 5rem;
}

.filters-mobile {
  display: none;
}

.offers-list, .details {
  height: calc(100vh - 161px);
}

// Details
.details {
  overflow: scroll;
  position: relative;
}

.details-card {
  // margin-bottom: 100px !important;
}

.company-info-mobile {
  display: none;
}

.inputfile {
  width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

// .dropzone {
//   z-index: 2;
//   position: relative;
// }

.dropzone{
  z-index: 2;
  position: relative;
  font-size: 1.25em;
  font-weight: 700;
  color: #20126e;
  background-color: #edf2f7;
  border: 2px dotted #20126e;
  display: block;
  width: 100%;
  border-radius: 5px;
  // position: absolute;
  padding: 30px;
  text-align: center;
  margin-bottom: 20px;
}

.inputfile-label {
  position: relative;

}

.cities {
  height: 100px;
  padding-top: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.map-section {
  height: calc(100vh - 150px);
}

.back-icon:hover {
  cursor: pointer;
}

.form-visible {
  visibility: visible;
  height: auto;
}

.form-hidden {
  visibility: hidden;
  height: 1px;
}

.quick-apply:hover {
  cursor: pointer;
}

// Leaflet popup
.pin-popup {
  .leaflet-popup-close-button {
    display: none;
  }
  .leaflet-popup-content-wrapper {
    padding: 10px;
    .leaflet-popup-content {
      margin: 0;
    }
    p {
      margin: 5px 0;
    }
  } 
}

// Modal

.modal {
  transition: opacity 0.25s ease;
  z-index: 1000;
  .modal-container {
    overflow: auto;
  }
}
modal-active {
  overflow-x: hidden;
  overflow-y: visible !important;
}

.skill-item.vertical {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-top: 1px solid #f6f6f6;
  img {
    margin-left: 10px; 
    width: 20%;
  }
  p {
    width: 80%;
    text-align: left;
    text-transform: uppercase;
  }
}

// .company-info-mobile {
//   display: none;
// }

.technologies-mobile {
  display: none;
}

.top-filters .map-btn {
  display: none;
}

// Skills
.skill-item {
  flex: 1 1 0;
  width: 0;
  min-width: 65px;
  cursor: pointer;
  &:hover{
    font-weight: 600;
  }
}
.close-skills {
  position: fixed;
  right: 20px;
  top: 20px;
}

// Post job
#post-job {
  height: calc(100vh - 64px);
  overflow:auto;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="range"]::-webkit-slider-thumb {
      width: 15px;
      -webkit-appearance: none;
  appearance: none;
      height: 15px;
      cursor: ew-resize;
      background: #FFF;
      box-shadow: -405px 0 0 400px #605E5C;
      border-radius: 50%;
  }
}

@media only screen and (max-width: 1300px) {
  .technologies {
    width: calc(100vw - 100px);
    overflow: hidden;
  }
  .technologies-mobile {
    display: flex;
    padding: 26px 0;
  }
  .skills-container.show {
    display: block;
    flex-direction: column;
    overflow: visible;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    background: white;
    overflow-x: auto;
    z-index: 9999;
  }
  .skills-container.hide {
    display: none;
    height: 1px;
    overflow: hidden;
  }
  //Skill bar
  #skill-bar {
    display: flex;
    position: relative;
  }
}

@media only screen and (max-width: 1199px) {
  .list {
    overflow: auto;
    // height: calc(100vh - 370px);
  }
  .offer-item {
    padding: 0.3rem;
    .company-info {
      display: none;
    }
    .company-info-mobile {
      display: block;
      .first-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 10px;
        .job-properties {
          span {
            font-size: 10px;
            padding-right: 5px;
          }
        }
        .job-skills {
          span {
            font-size: 10px;
            margin-left: 2px;
            margin-right: 2px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  nav {
    .hidden-menu {
      visibility: hidden;
      height: 1px;
      transition: height 0.5s ease-out;
    }
    .visible-menu {
      visibility: visible;
      height: auto;
      transition: height 0.5s ease-in;
      button {
        margin-top: 10px;
      }
    }
    #post-job-nav {
      margin-top: 0.75rem;
      display: inline-block;
    }
  }
}

@media only screen and (max-width: 768px) {
  button {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    font-weight: 700 !important;
    font-size: 100% !important;
  }
  .lost {
    padding-bottom: 0;
  }
  .details-header {
    .header-info {
      width: 100%;
    }
  }
  .logo-container {
    width: 50%;
  }
  .description-boxes {
    display: inline;
    .desc-box {
      width: auto;
      margin: 20px;
    }
  }

  .technologies-mobile {
    display: block;
    position: relative;
    padding: 10px 0;
    max-width: none;
  }
  .skills-container.show{
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
  }

  // Skill bar
  #skill-bar {
    button {
      padding: 0;
    }
    .technologies {
      display: none;
    }  
  }

  .filter {
    display: none;
    &.mobile {
      display: block;
    }
  }
  .top-filters {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    padding: 1rem;
    .map-btn {
      display: inline-block;
      margin: 0.75rem 0;
    }
  }
  .offers-list {
    width: 100vw;
    padding-top: 1rem;
    .list {
      padding-bottom: 0;
    }
  }
  .offers-list, .details {
    height: calc(100vh - 208px);
    height: calc(var(--vh, 1vh) * 100 - 157px);
  }
  #post-job {
    height: calc(100vh - 114px);
    height: calc(var(--vh, 1vh) * 100 - 65px);
  }
  .details {
    width: 100vw;
  }
  .map-col {
    display: none;
  }

  .filters-modal {
      height: 100vh;
      height: calc(var(--vh, 1vh) * 100);
    .modal-overlay {
      height: 100%;
    }
    .modal-container {
      max-width: 100vw;
      width: 100vw;
      height: 100%;
      border-radius: 0;
      padding: 1rem;
    }
  }
  .filters-mobile {
    display: flex;
    align-items: center;
  }
  .filter-btn {
    margin-right: 5px;
    font-size: 12px;
    margin-left: 5px;
  }

  .filters button {
    padding-top: 0;
    padding-bottom: 0; 
  }
  .cities {
    height: auto;
  }
}

// #Map icon
@keyframes addBorder { 
  from { border: 10px solid #39ba44; } 
}

.animate-marker {
  border: 5px solid #39ba44;
  animation: addBorder 0.5s infinite alternate;
  border-radius: 100px;
  box-sizing: border-box;
}